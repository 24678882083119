import {
  AspectRatio,
  Box,
  Button,
  Center,
  chakra,
  Container,
  createIcon,
  Divider,
  Flex,
  HStack,
  Link,
  List,
  ListItem,
  SimpleGrid,
  Stack,
  useBreakpointValue,
  useSafeLayoutEffect,
  VStack,
} from "@chakra-ui/react"
import { BASE_URL, DESCRIPTION_SUFFIX } from "components/constants"
import { ArrowIcon } from "components/icons/icons"
import { Footer } from "components/layouts/footer"
import { ChakraSwiper, SwiperSlide } from "components/lib/ChakraSwiper"
import { setPageAttr } from "components/lib/setPageAttr"
import Monicrew from "components/media"
import { EntryList, EntryListProps } from "components/recruit/EntryList"
import RecruitHeader, { HeaderSpacer } from "components/recruit/RecruitHeader"
import { MemberCard } from "components/recruit/MemberCard"
import { NextImage } from "components/system/NextImage"
import { Wbr } from "components/system/text/wbr"
import { breakpoints } from "components/theme/breakpoints"
import { motion, useScroll } from "framer-motion"
import { imgixImageLoader } from "lib/imgix"
import { CMSClient } from "lib/microcms/client"
import { parseJobs } from "lib/microcms/jobs"
import {
  Member as MemberSchema,
  MoniclePlus as MoniclePlusItem,
  SiteConfig,
} from "lib/microcms/schema/schema"
import { MakeRequired } from "lib/typeutil"
import { GetStaticProps, NextPage } from "next"
import { NextSeo } from "next-seo"
import Image from "next/image"
import {
  ComponentProps,
  FC,
  ReactNode,
  useEffect,
  useMemo,
  useRef,
  useState,
  VFC,
} from "react"
import { Element as ScrollElement } from "react-scroll"
import { Autoplay, EffectFade } from "swiper"
import { isPropertyTruthy } from "typesafe-utils"
import { MEMBER_LIMIT } from "components/recruit/constant"

const PAGE_TITLE = "採用サイト"
const PAGE_DESCRIPTION = `${DESCRIPTION_SUFFIX}`
const MEMBERS = 6

const About = (props: Pick<Props, "siteConfig">) => {
  const isMobile = useBreakpointValue({ base: true, lg: false }, "lg")

  return (
    <chakra.div background="white" id="aboutus">
      <Container
        maxW="container.xl"
        textColor="text-main-black"
        px={{ base: "5", lg: "24" }}
        py={{ base: "8", lg: "20" }}
      >
        <Flex
          flexDirection={{ base: "column", lg: "row" }}
          alignItems={{ base: "center", lg: "baseline" }}
        >
          <chakra.p
            fontFamily="serif"
            fontWeight={700}
            fontSize={"80px"}
            lineHeight={"75px"}
            mr={{lg:5}}
          >
            About us
          </chakra.p>
          <chakra.p
            textStyle={{ base: "title-bold-14pt", lg: "title-bold-15pt-wide" }}
            lineHeight={{ base: undefined, lg: "32px" }}
          >
            モニクルとは
          </chakra.p>
        </Flex>
        <chakra.p
          textStyle="title-bold-13pt"
          fontSize={{ base: "24px", lg: "54px" }}
          letterSpacing={0}
          lineHeight={{ base: "43px", lg: "73px" }}
          mt={{ base: 6, lg: 7 }}
        >
          モニクルは、
          <br />
          金融サービステック企業です
        </chakra.p>
        <chakra.p
          textStyle={"title-bold-13pt"}
          fontSize={{ base: "13px", lg: "14px" }}
          lineHeight={{ base: "24px", lg: "30px" }}
          letterSpacing={{ base: "0.05em", lg: "0.14em" }}
          mt={{ base: 4, lg: 7 }}
        >
          モニクルはグループの統括会社として、モニクルフィナンシャル、モニクルリサーチと連携しつつ、<br />
          金融サービスプラットフォーム事業の開発・育成に取り組んでいます。<br />
          また、グループ全体の経営・プロダクトシステム開発・デザイン・マーケティングを担っています。
        </chakra.p>
        <chakra.p
          textStyle={"title-bold-13pt"}
          fontSize={{ base: "13px", lg: "14px" }}
          lineHeight={{ base: "24px", lg: "30px" }}
          letterSpacing={{ base: "0.05em", lg: "0.14em" }}
          mt={{ base: 4, lg: 6 }}
        >
          「モニクル」という社名には、「Money＋Lifecycle」という意味が込められています。<br />
          一人ひとりのライフサイクルに寄り添いながら、それぞれのお金の課題に対する確かな解決策を提示できる存在でありたい。<br />
          その思いが、事業の原点にあります。時代や社会に対して誠実に向き合いながら唯一無二のソリューションを生み出し、{isMobile ? null:<br />}
          心地よいUX（ユーザーエクスペリエンス）を提供してまいります。
        </chakra.p>
        <AspectRatio
          mx="auto"
          maxW="100%"
          ratio={16 / 9}
          mt={{ base: 6, lg: 14 }}
        >
          <iframe src={props.siteConfig.company_sd_url} allow="fullscreen" />
        </AspectRatio>
        <Flex
          flexDirection={{ base: "column", lg: "row" }}
          alignItems={{ base: "center", lg: "baseline" }}
          mt={{base: 10, lg: 16}}
        >
          <chakra.p
            fontFamily="serif"
            fontWeight={700}
            fontSize={{ base: "60px", lg: "80px" }}
            lineHeight={{ base: "53.06px", lg: "84.08px" }}
            mr={{lg:5}}
          >
            Our service
          </chakra.p>
          <chakra.p
            textStyle={{ base: "title-bold-14pt", lg: "title-bold-15pt-wide" }}
            lineHeight={{ base: undefined, lg: "32px" }}
          >
            グループが展開するサービス
          </chakra.p>
        </Flex>
        <Stack
          direction={{ base: "column", lg: "row" }}
          justifyContent="center"
          alignItems={"center"}
          maxW="100%"
          mx="auto"
          spacing={{ base: 4, lg: 4 }}
          mt={{ base: 6, lg: 4 }}
        >
          {isMobile ? (
            <Link href="https://moneiro.jp/" isExternal>
              <Image
                src="/images/recruit/moneiro-sp.svg"
                alt="moneiro 働く世代のお金の診断・相談サービス"
                width="642px"
                height="290px"
              />
            </Link>
          ): (
            <Link href="https://moneiro.jp/" isExternal>
              <Image
                src="/images/recruit/moneiro.svg"
                alt="moneiro 働く世代のお金の診断・相談サービス"
                width="470"
                height="159"
              />
            </Link>
          )}
          {isMobile ? (
            <Link href="https://limo.media/" isExternal>
              <Image
                src="/images/recruit/limo-sp.svg"
                alt="limo くらしとお金の経済メディア"
                width="642px"
                height="290px"
              />
            </Link>
          ):(
            <Link href="https://limo.media/" isExternal>
              <Image
                src="/images/recruit/limo.svg"
                alt="limo くらしとお金の経済メディア"
                width="470px"
                height="159px"
              />
            </Link>
          )}
        </Stack>
      </Container>
    </chakra.div>
  )
}

const Member: VFC<Props> = ({ articleMembers: members }) => {
  const isMobile = useBreakpointValue({ base: true, lg: false }, "lg")
  return (
    <Container
      maxW="container.xl"
      textAlign="center"
      py={{ base: "9", lg: "20" }}
      px={{ base: "3", lg: "24" }}
      id="member"
    >
      <Flex
        flexDirection={{ base: "column", lg: "row" }}
        alignItems={{ base: "center", lg: "baseline" }}
      >
        <chakra.p
          fontFamily="serif"
          fontWeight={700}
          fontSize={{ base: "60px", lg: "80px" }}
          lineHeight={{ base: "53.06px", lg: "84.08px" }}
          mr={5}
        >
          Member
        </chakra.p>
        <chakra.p
          textStyle={{ base: "title-bold-14pt", lg: "title-bold-15pt-wide" }}
          lineHeight={{ base: undefined, lg: "32px" }}
        >
          働いている人びと
        </chakra.p>
      </Flex>
      <Box
        textAlign={"start"}
        pt={6}
      >
        <chakra.p
          textStyle={{ base: "title-bold-13pt", lg: "title-bold-14pt" }}
        >
          モニクルではたらくスタッフに、入社した理由や、仕事やプロジェクトの内容、{isMobile ? null: <br />}
          仕事への想いややりがいについてインタビューしました。
        </chakra.p>
      </Box>
      <SimpleGrid
        columns={{ base: 2, lg: 3 }}
        spacingX={{ base: 2, lg: 12 }}
        spacingY={{ base: 2, lg: 10 }}
        mt={{ base: 7, lg: 10 }}
      >
        {members.map((member) => (
          <MemberCard key={member.id} {...member} />
        ))}
      </SimpleGrid>
      <Button
        as="a"
        href={"/member"}
        variant="normal"
        w="full"
        maxW="345px"
        size="lg"
        mt={{ base: 6, lg: 10 }}
      >
        すべてを見る
      </Button>
    </Container>
  )
}

const Meeting: VFC<Props> = (props) => {
  const isMobile = useBreakpointValue({ base: true, lg: false }, "lg")
  return (
    <chakra.div bgColor="white" id="meeting">
      <Container
        maxW="container.xl"
        pt={{ base: 9, lg: 20 }}
        pb={{ base: 16, lg: 20 }}
        px={0}
      >
        <Box px={{ base: 3, lg: 24 }} w="full">
          <Flex
            flexDirection={{ base: "column", lg: "row" }}
            alignItems={{ base: "center", lg: "baseline" }}
          >
            <chakra.p
              fontFamily="serif"
              fontWeight={700}
              fontSize={{ base: "60px", lg: "80px" }}
              lineHeight={{ base: "53.06px", lg: "84.08px" }}
              mr={{lg: 5}}
            >
              Meeting
            </chakra.p>
            <chakra.p
              textStyle={{ base: "title-bold-14pt", lg: "title-bold-15pt-wide" }}
              lineHeight={{ base: undefined, lg: "32px" }}
            >
              カジュアルミーティング
            </chakra.p>
          </Flex>
        </Box>
        <VStack spacing={{ base: 10, lg: 16 }} mt={{base: 12, lg: 6}}>
          <Stack
            direction={{ base: "column", lg: "row" }}
            alignItems="center"
            pr={{ base: 3, lg: 24 }}
            pl={{ base: 3, lg: 0 }}
            spacing={{ base: 8, lg: 14 }}
          >
            <Box flex={56} maxW="560px">
              <NextImage
                src="/images/recruit/meeting.jpg"
                width={560}
                height={365}
              />
            </Box>
            <Box flex={45}>
              <chakra.p
                textStyle={{ base: "title-bold-23pt", lg: "title-bold-25pt" }}
              >
                「会社や仕事について聞いてみたい」 というみなさまへ
              </chakra.p>
              <chakra.p
                textStyle={{ base: "title-bold-13pt", lg: "title-bold-14pt" }}
                mt={5}
                mb={6}
              >
                選考とは別に、会社の雰囲気や業務内容についてお話しする「カジュアル面談」を実施しています。当社に少しでもご興味をお持ち頂いている方は、まずはカジュアルに話しませんか。
              </chakra.p>
              <Box w="full" textAlign={{ base: "center", lg: "left" }}>
                <Button
                  as="a"
                  href={props.siteConfig.casual_meeting}
                  variant="normal"
                  w="345px"
                  size="lg"
                  target="_blank"
                  rel="noopener noreferrer"
                  textStyle="title-bold-14pt"
                  position="relative"
                >
                  カジュアル面談を申し込む
                  <ArrowIcon
                    position="absolute"
                    right={4}
                    w={"12px"}
                    h={"12px"}
                  />
                </Button>
              </Box>
            </Box>
          </Stack>
          <Box px={{ base: 3, lg: 24 }} w="full">
            <Stack
              direction={{ base: "column", lg: "row" }}
              bgColor="fill-main-gray"
              w="full"
              alignItems="center"
              justifyContent="center"
              py={{ base: 6, lg: 10 }}
              borderRadius="10px"
              spacing={{ base: 8, lg: 20 }}
            >
              <CasualMeetingCard
                text={
                  <>
                    フルリモートでの開発体制や開発プロセス、
                    <br />
                    技術スタックについて知りたい方は
                    <br />
                    CTOとカジュアルに話したり、相談できます。
                  </>
                }
                linkLabel={
                  <>
                    CTOとの{isMobile ? <br /> : null}
                    カジュアル面談を申し込む
                  </>
                }
                person={{
                  imageUrl: "/images/recruit/tsukada.png",
                  job: "CTO",
                  name: "Shoya Tsukada",
                }}
                formUrl={props.siteConfig.casual_meeting_cto}
              />
              <CasualMeetingCard
                text={
                  <>
                    どんなスキルが必要？選考フローは？など、
                    <br />
                    採用や会社について知りたい方は{isMobile ? null : <br />}
                    採用担当者と
                    {isMobile ? <br /> : null}
                    カジュアルに話したり、相談できます。
                  </>
                }
                linkLabel={
                  <>
                    採用担当者との{isMobile ? <br /> : null}
                    カジュアル面談を申し込む
                  </>
                }
                person={{
                  imageUrl: "/images/recruit/yanagi.png",
                  job: "採用担当",
                  name: "Naoko Yanagi",
                }}
                formUrl={props.siteConfig.casual_meeting_hr}
              />
            </Stack>
          </Box>
        </VStack>
      </Container>
    </chakra.div>
  )
}

const CasualMeetingCard: FC<{
  text: ReactNode
  linkLabel: ReactNode
  person: {
    imageUrl: string
    job: string
    name: string
  }
  formUrl: string
}> = ({ text, linkLabel, person, formUrl }) => {
  return (
    <Box w={{ base: "292px", lg: "350px" }}>
      <VStack spacing={5} textAlign="center">
        <chakra.p
          textStyle={{ base: "body-medium-12pt", lg: "title-bold-14pt" }}
        >
          {text}
        </chakra.p>
        <Center w={{ base: "253px", lg: "343px" }} overflow={"hidden"}>
          <CrossingLineIcon w="343px" h="23px" />
        </Center>
        <Box>
          <chakra.img w="127px" h="127px" src={person.imageUrl} />
          <chakra.p textStyle="body-medium-12pt" mt={{ base: 3, lg: 2 }}>
            {person.job}
          </chakra.p>
          <chakra.p textStyle="title-bold-13pt" mt={1}>
            {person.name}
          </chakra.p>
        </Box>
      </VStack>
      <Button
        mt={6}
        as="a"
        href={formUrl}
        variant="normal"
        w="full"
        size="lg"
        target="_blank"
        rel="noopener noreferrer"
        px={3}
        whiteSpace="normal"
      >
        <HStack spacing={2}>
          <Box flex={1} textAlign="center">
            <chakra.p
              textStyle={{
                base: "title-bold-13pt",
                lg: "title-bold-14pt-wide",
              }}
            >
              {linkLabel}
            </chakra.p>
          </Box>
          <ArrowIcon w={"12px"} h={"12px"} />
        </HStack>
      </Button>
    </Box>
  )
}

const CrossingLineIcon = createIcon({
  viewBox: "0 0 344 23",
  path: [
    <g key={1} clipPath="url(#clip0_3124_15259)">
      <path
        d="M168.516 22.7168L190.5 0.393311H341.001"
        stroke="#666666"
        strokeMiterlimit="10"
      />
      ,
      <path
        d="M166 0H0"
        stroke="#666666"
        strokeWidth="2"
        strokeMiterlimit="10"
      />
      ,
    </g>,
    <defs key={2}>
      <clipPath id="clip0_3124_15259">
        <rect width="344" height="23" fill="white" />
      </clipPath>
    </defs>,
  ],
  defaultProps: {
    fill: "none",
  },
})

const Jobs = (props: Pick<Props, "jobs">) => {
  return (
    <chakra.div bgColor="text-main-black" py={{ base: 10, lg: 20 }} id="jobs">
      <Container maxW="container.xl" px={{ base: 3, lg: 24 }}>
        <Flex
          flexDirection={{ base: "column", lg: "row" }}
          alignItems={{ base: "center", lg: "baseline" }}
          color={"white"}
        >
          <chakra.p
            fontFamily="serif"
            fontWeight={700}
            fontSize={{ base: "60px", lg: "80px" }}
            lineHeight={{ base: "53.06px", lg: "84.08px" }}
            mr={{lg: 5}}
          >
            Jobs
          </chakra.p>
          <chakra.p
            textStyle={{ base: "title-bold-14pt", lg: "title-bold-15pt-wide" }}
            lineHeight={{ base: undefined, lg: "32px" }}
          >
            募集要項
          </chakra.p>
        </Flex>
        <EntryList my={{ base: 6, lg: 14 }} entries={props.jobs} />
      </Container>
    </chakra.div>
  )
}

const benefitItems: {
  title: string
  contents: string[]
  icon: VFC<ComponentProps<typeof chakra.svg>>
}[] = [
  {
    title: "働き方",
    contents: [
      "完全フルリモート（北米、台湾、北海道、新潟など）",
      "裁量労働制（夕方の子供の送り迎えなど柔軟に働けます）",
    ],
    icon: (props) => (
      <chakra.svg
        width="53px"
        height="47px"
        viewBox="0 0 53 47"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
      >
        <g clipPath="url(#clip0_2387_8851)">
          <path
            d="M15.1769 30.1941C17.5528 30.1941 19.4789 28.3153 19.4789 25.9977C19.4789 23.6801 17.5528 21.8013 15.1769 21.8013C12.801 21.8013 10.875 23.6801 10.875 25.9977C10.875 28.3153 12.801 30.1941 15.1769 30.1941Z"
            stroke="#333333"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M19.2779 41.6353H15.3683H26.3194C26.8378 41.6353 27.335 41.4344 27.7016 41.0768C28.0682 40.7192 28.2742 40.2342 28.2742 39.7285C28.2742 39.2227 28.0682 38.7377 27.7016 38.3801C27.335 38.0225 26.8378 37.8216 26.3194 37.8216H19.2779V34.0079C19.2761 33.1994 18.9465 32.4243 18.3611 31.852C17.7756 31.2796 16.9819 30.9564 16.153 30.9529H12.6357C11.8062 30.9564 11.0117 31.2794 10.4252 31.8516C9.83864 32.4237 9.50753 33.1987 9.50391 34.0079V43.9249C9.50575 44.3604 9.60386 44.7903 9.79154 45.1854C9.97922 45.5806 10.252 45.9315 10.5914 46.2144H23.9585C23.9585 45.0023 23.4658 43.8396 22.5884 42.9813C21.711 42.1229 20.5206 41.6389 19.2779 41.6353V41.6353Z"
            stroke="#333333"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M31.4091 30.9529L27.9676 38.7415C28.1309 39.0006 28.2281 39.2941 28.2509 39.5975C28.2738 39.9008 28.2216 40.2051 28.0989 40.4847C27.9761 40.7644 27.7864 41.0111 27.5455 41.2043C27.3047 41.3975 27.0198 41.5315 26.7148 41.595V41.6353H39.2283L43.9226 30.9529H31.4091Z"
            stroke="#333333"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M43.9204 41.6018H26.3203V41.6757H43.9204V41.6018Z"
            stroke="#333333"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M34.3726 41.6418H34.2969V46.221H34.3726V41.6418Z"
            stroke="#333333"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M52.0861 45.8989V15.7316L26.7149 1.30933L1.34375 15.7316V45.8989"
            stroke="#333333"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </g>
        <defs>
          <clipPath id="clip0_2387_8851">
            <rect width="53" height="47" fill="white" />
          </clipPath>
        </defs>
      </chakra.svg>
    ),
  },
  {
    title: "休暇",
    contents: [
      "完全週休二日制／慶弔休暇／育児休暇",
      "入社時有給休暇付与（10日間）",
      "結婚休暇（連続3日間）",
      "ワクチン接種休暇",
    ],
    icon: (props) => (
      <chakra.svg
        width="50px"
        height="49px"
        viewBox="0 0 50 49"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
      >
        <g clipPath="url(#clip0_2387_8830)">
          <path
            d="M47.1168 41.7322C47.1141 43.3547 46.4771 44.9099 45.3454 46.0572C44.2137 47.2045 42.6796 47.8502 41.0792 47.8531H6.86964C5.27007 47.8474 3.73759 47.2008 2.60652 46.0541C1.47545 44.9074 0.837566 43.3538 0.832031 41.7322H47.1168Z"
            stroke="#333333"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M47.1194 21.3259H42.0898V25.4136C42.09 29.1525 40.8179 32.7769 38.4883 35.6754C38.7911 35.7561 39.1094 35.7561 39.4123 35.6754C42.1425 35.1176 44.5998 33.6234 46.3707 31.4442C48.1416 29.265 49.1179 26.5338 49.1355 23.7104V23.3698C49.1355 22.8277 48.9231 22.3078 48.545 21.9245C48.1669 21.5413 47.6541 21.3259 47.1194 21.3259V21.3259Z"
            stroke="#333333"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M42.0889 19.2822V25.4137C42.0751 29.7301 40.3806 33.8663 37.3739 36.9224C34.3672 39.9786 30.2917 41.7073 26.0341 41.7326H21.9601C17.6952 41.7185 13.6089 39.9947 10.5932 36.9374C7.57747 33.88 5.87712 29.7374 5.86328 25.4137V19.2929L42.0889 19.2822Z"
            stroke="#333333"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M14.0729 33.408C13.0333 32.3607 12.2085 31.1151 11.6462 29.743C11.0839 28.3708 10.7952 26.8994 10.7969 25.4136H10.9964C10.9939 26.8748 11.2765 28.3222 11.8279 29.6722C12.3793 31.0223 13.1887 32.2485 14.2094 33.2802L14.0729 33.408Z"
            stroke="#333333"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M24.0494 11.1917L23.9024 11.0533C24.2256 10.7287 24.4821 10.3423 24.6572 9.91662C24.8323 9.4909 24.9224 9.03423 24.9224 8.573C24.9224 8.11177 24.8323 7.65511 24.6572 7.22939C24.4821 6.80368 24.2256 6.41736 23.9024 6.09273C23.217 5.39625 22.832 4.45256 22.832 3.46872C22.832 2.48487 23.217 1.5412 23.9024 0.844727L24.0494 0.993756C23.4044 1.65361 23.0425 2.54503 23.0425 3.47405C23.0425 4.40306 23.4044 5.29448 24.0494 5.95433C24.7342 6.64899 25.1189 7.5909 25.1189 8.573C25.1189 9.5551 24.7342 10.497 24.0494 11.1917V11.1917Z"
            stroke="#333333"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M15.9959 13.2356L15.8594 13.0866C16.5039 12.4286 16.8655 11.5389 16.8655 10.6116C16.8655 9.68437 16.5039 8.79471 15.8594 8.13667C15.174 7.4402 14.7891 6.49651 14.7891 5.51266C14.7891 4.52882 15.174 3.58515 15.8594 2.88867L15.9959 3.0377C15.3515 3.69574 14.9899 4.5854 14.9899 5.51266C14.9899 6.43993 15.3515 7.3296 15.9959 7.98764C16.6814 8.68411 17.0663 9.62778 17.0663 10.6116C17.0663 11.5955 16.6814 12.5391 15.9959 13.2356V13.2356Z"
            stroke="#333333"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M32.1041 13.2356L31.9571 13.0866C32.6015 12.4286 32.9632 11.5389 32.9632 10.6116C32.9632 9.68437 32.6015 8.79471 31.9571 8.13667C31.2717 7.4402 30.8867 6.49651 30.8867 5.51266C30.8867 4.52882 31.2717 3.58515 31.9571 2.88867L32.1041 3.0377C31.7812 3.36142 31.5249 3.74686 31.35 4.17169C31.175 4.59652 31.085 5.0523 31.085 5.51266C31.085 5.97303 31.175 6.42882 31.35 6.85365C31.5249 7.27848 31.7812 7.66392 32.1041 7.98764C32.7895 8.68411 33.1745 9.62778 33.1745 10.6116C33.1745 11.5955 32.7895 12.5391 32.1041 13.2356Z"
            stroke="#333333"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </g>
        <defs>
          <clipPath id="clip0_2387_8830">
            <rect width="50" height="49" fill="white" />
          </clipPath>
        </defs>
      </chakra.svg>
    ),
  },
  {
    title: "働く環境",
    contents: [
      "入社時PC選択制",
      "リモートワーク準備支援（ディスプレイ、マウス、キーボード、ヘッドセット、マイク）",
      "Krisp（ノイズキャンセルソフト）の配布",
      "社内懇親会（オンライン含む）の飲食費用補助",
    ],
    icon: (props) => (
      <chakra.svg
        width="50px"
        height="52px"
        viewBox="0 0 50 52"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
      >
        <path
          d="M49 25H1V31H49V25Z"
          stroke="#333333"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M9 31H3V51H9V31Z"
          stroke="#333333"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M47 31H29V41H47V31Z"
          stroke="#333333"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M47 41H29V51H47V41Z"
          stroke="#333333"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M39 45.99H37V46.01H39V45.99Z"
          stroke="#333333"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M39 35.99H37V36.01H39V35.99Z"
          stroke="#333333"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M27 20H23V25H27V20Z"
          stroke="#333333"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M36 1H14C12.8954 1 12 1.89543 12 3V18C12 19.1046 12.8954 20 14 20H36C37.1046 20 38 19.1046 38 18V3C38 1.89543 37.1046 1 36 1Z"
          stroke="#333333"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </chakra.svg>
    ),
  },
  {
    title: "その他",
    contents: [
      "リファラル採用による社員紹介手当の支給（最大30万円）",
      "関東ITソフトウェア健康保険組合"
    ],
    icon: (props) => (
      <chakra.svg
        width="51px"
        height="44px"
        viewBox="0 0 51 44"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
      >
        <g clipPath="url(#clip0_2387_8838)">
          <path
            d="M10.6726 2.6499H1.77989C1.28876 2.6499 0.890625 3.04538 0.890625 3.53324V40.6332C0.890625 41.1211 1.28876 41.5166 1.77989 41.5166H10.6726C11.1637 41.5166 11.5618 41.1211 11.5618 40.6332V3.53324C11.5618 3.04538 11.1637 2.6499 10.6726 2.6499Z"
            stroke="#333333"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M8.00238 37.9392H4.44531V38.0275H8.00238V37.9392Z"
            stroke="#333333"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M8.00238 6.13916H4.44531V6.22749H8.00238V6.13916Z"
            stroke="#333333"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M22.2298 6.18335H12.4479C11.9567 6.18335 11.5586 6.57883 11.5586 7.06668V40.6334C11.5586 41.1212 11.9567 41.5167 12.4479 41.5167H22.2298C22.7209 41.5167 23.1191 41.1212 23.1191 40.6334V7.06668C23.1191 6.57883 22.7209 6.18335 22.2298 6.18335Z"
            stroke="#333333"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M19.5635 37.9392H15.1172V38.0275H19.5635V37.9392Z"
            stroke="#333333"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M19.5635 9.67261H15.1172V9.76094H19.5635V9.67261Z"
            stroke="#333333"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M31.1245 0.883301H24.0104C23.5192 0.883301 23.1211 1.27878 23.1211 1.76663V40.6333C23.1211 41.1212 23.5192 41.5166 24.0104 41.5166H31.1245C31.6156 41.5166 32.0138 41.1212 32.0138 40.6333V1.76663C32.0138 1.27878 31.6156 0.883301 31.1245 0.883301Z"
            stroke="#333333"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M28.4582 37.9392H26.6797V38.0275H28.4582V37.9392Z"
            stroke="#333333"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M28.4582 4.37256H26.6797V4.46089H28.4582V4.37256Z"
            stroke="#333333"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M40.9391 10.0822L34.0384 11.9195C33.562 12.0463 33.2793 12.5328 33.407 13.006L41.2681 42.1383C41.3958 42.6116 41.8855 42.8923 42.3619 42.7655L49.2626 40.9282C49.739 40.8013 50.0217 40.3149 49.894 39.8417L42.0329 10.7093C41.9052 10.2361 41.4155 9.95533 40.9391 10.0822Z"
            stroke="#333333"
            strokeWidth="2.00848"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M44.3312 36.8797L42.6133 37.3369L42.6363 37.4222L44.3542 36.965L44.3312 36.8797Z"
            stroke="#333333"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M38.7531 13.9024L37.0352 14.3596L37.0582 14.4449L38.7761 13.9877L38.7531 13.9024Z"
            stroke="#333333"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </g>
        <defs>
          <clipPath id="clip0_2387_8838">
            <rect width="51" height="44" fill="white" />
          </clipPath>
        </defs>
      </chakra.svg>
    ),
  },
]

const Benefit = () => {
  const isMobile = useBreakpointValue({ base: true, lg: false }, "lg")

  return (
    <chakra.div bgColor="fill-main-grey" py={{ base: 10, lg: 18 }} id="benefit">
      <Container
        maxW="container.xl"
        textColor="text-main-black"
        textAlign={"center"}
        px={{ base: 3, lg: 24 }}
      >
        <Flex
          flexDirection={{ base: "column", lg: "row" }}
          alignItems={{ base: "center", lg: "baseline" }}
        >
          <chakra.p
            fontFamily="serif"
            fontWeight={700}
            fontSize={{ base: "60px", lg: "80px" }}
            lineHeight={{ base: "53.06px", lg: "84.08px" }}
            mr={{lg: 5}}
          >
            Benefit
          </chakra.p>
          <chakra.p
            textStyle={{ base: "title-bold-14pt", lg: "title-bold-15pt-wide" }}
            lineHeight={{ base: undefined, lg: "32px" }}
          >
            モニクルでの働き方
          </chakra.p>
        </Flex>
        {isMobile ? (
          <VStack mt={7} spacing={3}>
            {benefitItems.map((item) => (
              <chakra.div
                key={item.title}
                bgColor="white"
                borderRadius="10px"
                px={5}
                w="full"
              >
                <Flex
                  h={16}
                  justifyContent="center"
                  alignItems="center"
                  position="relative"
                >
                  <item.icon
                    w="45px"
                    h="40px"
                    position="absolute"
                    left={6}
                    top={4}
                  />
                  <chakra.p
                    fontWeight="700"
                    fontSize="18px"
                    lineHeight="28px"
                    letterSpacing="0.08em"
                  >
                    {item.title}
                  </chakra.p>
                </Flex>
                <Divider borderColor="text-main-black" borderWidth={1} />
                <List textStyle="body-medium-12pt" textAlign="left" py={3}>
                  {item.contents.map((content) => (
                    <ListItem key={content} display="flex">
                      <chakra.p mr={1}>●</chakra.p>
                      <chakra.p>{content}</chakra.p>
                    </ListItem>
                  ))}
                </List>
              </chakra.div>
            ))}
          </VStack>
        ) : (
          <chakra.div bgColor="white" px={12} mt={12} borderRadius="10px">
            <VStack
              divider={
                <Divider borderColor="text-main-black" borderWidth={1} />
              }
            >
              {benefitItems.map((item) => (
                <Flex key={item.title} w="full" h="170px">
                  <Center w="280px">
                    <VStack spacing={3}>
                      <chakra.p
                        fontWeight="700"
                        fontSize="18px"
                        lineHeight="28px"
                        letterSpacing="0.08em"
                      >
                        {item.title}
                      </chakra.p>
                      <item.icon />
                    </VStack>
                  </Center>
                  <Flex textAlign="left" w="full" h="full" alignItems="center">
                    <List textStyle="title-bold-13pt">
                      {item.contents.map((content) => (
                        <ListItem
                          key={content}
                          _before={{
                            content: `"●"`,
                            mr: 2,
                          }}
                        >
                          {content}
                        </ListItem>
                      ))}
                    </List>
                  </Flex>
                </Flex>
              ))}
            </VStack>
          </chakra.div>
        )}
      </Container>
    </chakra.div>
  )
}

const Recruit: NextPage<Props> = (props) => {
  return (
    <Box>
      <NextSeo
        title={PAGE_TITLE}
        description={PAGE_DESCRIPTION}
        canonical={`${BASE_URL}/`}
      />
      <RecruitHeader />
      <HeaderSpacer />
      <ScrollElement name="aboutus">
        <About {...props} />
      </ScrollElement>
      <ScrollElement name="member">
        <Member {...props} />
      </ScrollElement>
      <ScrollElement name="meeting">
        <Meeting {...props} />
      </ScrollElement>
      <ScrollElement name="jobs">
        <Jobs {...props} />
      </ScrollElement>
      <ScrollElement name="benefit">
        <Benefit />
      </ScrollElement>
      <chakra.div bg="white" w="full">
        <Monicrew
          url="https://media.monicle.co.jp/?utm_source=monicle&utm_medium=referral&utm_campaign=monicle-recruit-top"
          items={props.moniclePlusItems}
          containerSx={{
            px: { base: 3, lg: 24 },
          }}
        />
      </chakra.div>
      <Footer />
    </Box>
  )
}

export default Recruit

setPageAttr(Recruit, { getLayout: null })

const memberFields = [
  "title",
  "id",
  "familyName",
  "givenName",
  "team",
  "jobTitle",
  "profilePicture",
  "profilePictureSquare",
  "jobTagTop1",
  "jobTagTop2",
  "articleAvailable",
  "kvAvailable",
  "jobTitleEn",
  "placeEn",
  "placeJa",
  "kvPicture",
] as const

type Member = Pick<MemberSchema, typeof memberFields[number]>

type Props = {
  /**
   * キービジュアルに表示されるメンバー
   */
  kvMembers: Pick<Member, "kvAvailable" | "kvPicture">[]
  /**
   * 記事があるメンバー
   */
  articleMembers: Member[]

  /**
   * 募集要項
   */
  jobs: EntryListProps["entries"]

  /**
   * サイト設定
   */
  siteConfig: Pick<
    SiteConfig,
    | "company_sd_url"
    | "casual_meeting"
    | "casual_meeting_cto"
    | "casual_meeting_hr"
  >

  /**
   * モニクルプラスの記事
   */
  moniclePlusItems: MoniclePlusItem[]
}

export const getStaticProps: GetStaticProps<Props> = async (ctx) => {
  const [listRes, siteConfig, listPlus, listKv] = await Promise.all([
    CMSClient.current.listMember({
      fields: memberFields,
      limit: MEMBERS,
    }), // MicroCMSの登録順に表示したいのでorders不要
    CMSClient.current.siteConfig({
      fields: [
        "jobs",
        "company_sd_url",
        "casual_meeting",
        "casual_meeting_cto",
        "casual_meeting_hr",
      ],
    }),
    CMSClient.current.listMoniclePlus({}),
    CMSClient.current.listMember({
      fields: ["kvPicture", "kvAvailable"],
      limit: MEMBER_LIMIT,
      filters: "kvAvailable[equals]true",
    }),
  ])
  const all = listRes.contents

  const jobs: Props["jobs"] = parseJobs(siteConfig.jobs)

  const moniclePlusItems = listPlus.contents

  return {
    props: {
      kvMembers: listKv.contents,
      articleMembers: all.filter((m) => m.articleAvailable),
      jobs,
      siteConfig,
      moniclePlusItems,
    },
    revalidate: 300,
  }
}
